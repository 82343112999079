import "../Header/header.css";
import logoImg from "../../assets/images/logo_amis.png";
import { Link, NavLink } from "react-router-dom";
import donate_hands from "../../assets/images/donate_hands.png";

import Button from "react-bootstrap/Button";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Modal from "react-bootstrap/Modal";

import Signin from "../../pages/Signin";


import $ from "jquery";

const Header = () => {
  const [show, setShow] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    if (!document.getElementById('razorpay-script')) {
      const script = document.createElement('script');
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.setAttribute('data-payment_button_id', 'pl_ObgNujpEEu8sFM');
      script.async = true;
      script.id = 'razorpay-script'; // Assign an ID to the script
      const form = document.getElementById('razorpay-form');
      form.appendChild(script);
    }

    const handleScroll = () => {
      const header = document.querySelector(".header-section");
      const scrollTop = window.scrollY;
      if (scrollTop >= 250) {
        header.classList.add("is-sticky");
      } else {
        header.classList.remove("is-sticky");
      }

      if (scrollTop > 400) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const saveLife = () => setShowSave(true);
  const saveLifeClose = () => setShowSave(false);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const menuClose = (e) => {
    e.preventDefault();
    $(".navbar-toggler").trigger("click");
  };

  return (
    <section className="header">
      <Navbar expand="lg" className="header-section">
        <Container fluid className="plr-lg-100">
          <Navbar.Brand href="#">
            <div className="logo">
              <Link to="/">
                <img src={logoImg} alt="Logo" />
                <div className="align-self-center ms-2">
                  <h3 className="m-0 fw-bold">AIMS HUMAN</h3>
                  <p className="m-0">RIGHTS SOCIETY SURVEY'S</p>
                </div>
              </Link>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
              <Nav.Link href="/">Home</Nav.Link>
              <NavDropdown title="About Us" id="navbarScrollingDropdown" className="drp_m1">
                <NavDropdown.Item href="/about-us">Who we are</NavDropdown.Item>
                <NavDropdown.Item href="/activities">Activities</NavDropdown.Item>
                <NavDropdown.Item href="/team">Team</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Gallery" id="navbarScrollingDropdown2" className="drp_m1 drp_m1_sup1">
                <NavDropdown.Item href="/event-gallery">Event Gallery</NavDropdown.Item>
                <NavDropdown title="Art Gallery" id="navbarScrollingDropdown3">
                  <NavDropdown.Item href="/english-art-gallery">English</NavDropdown.Item>
                  <NavDropdown.Item href="/hindi-art-gallery">Hindi</NavDropdown.Item>
                  <NavDropdown.Item href="/telugu-art-gallery">Telugu</NavDropdown.Item>
                </NavDropdown>
              </NavDropdown>
              <Nav.Link href="/appreciations">Appreciations</Nav.Link>
              <Nav.Link href="/know-your-right">Know Your Right</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>
              <form id="razorpay-form" ></form>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>


      {/* Scroll to Top Button */}
      <div className="scrollTop" onClick={goToTop} style={{ display: showScroll ? "flex" : "none" }} />
    </section>
  );
};

export default Header;
