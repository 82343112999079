// import React from "react";
import { Route, Routes } from "react-router-dom";
import About from "../pages/About";
import Blogs from "../pages/Blogs";
import Curreny from "../pages/Curreny";
import Explore from "../pages/Explore";
import Exploreview from "../pages/Exploreview";
import Home from "../pages/Home";
import OrganisationReg from "../pages/OrganisationReg";
import Signin from "../pages/Signin";
import UserReg from "../pages/UserReg";
import CampaignWork from "../pages/CampaignWork";
import Team from "../pages/Team";
import Article from "../pages/Article";
import Activities from "../pages/Activities";
import Contact from "../pages/Contact";
import Events from "../pages/Events";
import Announcement from "../pages/Announcement";
import Paints from "../pages/Paints";
import Gallery1 from "../pages/Gallery";
import Awards from "../pages/Awards";
import Paintsenglish from "../pages/Paintsenglish";
import Paintshindi from "../pages/Paintsehindi";
import React, { useEffect } from 'react';
const Routers = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []); // Empty dependency array ensures this effect runs only once after the initial render

    return (

        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/team" element={<Team />} />
            <Route path="/know-your-right" element={<Article />} />
            <Route path="/activities" element={<Activities />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/events" element={<Events />} />
            <Route path="/announcement" element={<Announcement />} />
            <Route path="/event-gallery" element={<Gallery1 />} />
            <Route path="/telugu-art-gallery" element={<Paints />} />
            <Route path="/english-art-gallery" element={<Paintsenglish />} />
            <Route path="/hindi-art-gallery" element={<Paintshindi />} />
            
            <Route path="/appreciations" element={<Awards />} />

            <Route path="*" element={<Home />} />

        </Routes>
    )
};

export default Routers;